import { z } from 'zod';

export const EventParticipantSchema = z.object({
  id: z.string().min(1, "Participant ID must be a non-empty string"), // Just a non-empty string
  rank: z.number().int().min(1, "Rank must be a positive integer"),  // Ensure it's a positive integer starting from 1
});

export const EventSchema = z.object({
  id: z.string().optional(),
  eventName: z.string()
    .min(5, "Event Name must be at least 5 characters long.")
    .max(50, "Event Name must be less than 50 characters long."),
  description: z.string()
    .max(200, "Description must be less than 200 characters long."),
  location: z.string()
    .min(2, "Location must be at least 2 characters long.")
    .max(100, "Location must be less than 100 characters long."),
  startDate: z.string()
    .min(1, "Start Date must be provided."),
  startTime: z.string()
    .min(1, "Start Time must be provided."),
  endTime: z.string()
    .min(1, "End Time must be provided."),
  gatheringID: z.string(),
  eventParticipants: z.array(EventParticipantSchema).optional(), // Now an array of {id, rank} objects
});

export type Event = z.infer<typeof EventSchema>;
