import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { auth, firestore } from "../../config/FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import GatheringCustomizationForm from "../../components/GatheringCustomizationForm.js/GatheringCustomizationForm";
import FetchAnnouncements from "../../api/FetchAnnouncements";
import toCamelCase from "../../logic/toCamelCase";
import "../../styles/views/gathering-dashboard-view.css";
import daysUntil from "../../logic/daysUntil";
import Modal from "../../components/Modal/Modal";
import ModifyGathering from "../../api/ModifyGathering";
import { formatDate } from "../../logic/formatDate";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { QRCodeCanvas } from "qrcode.react";

import { generateDynamicLink, generateQRCode } from "../../utils/qrCodeUtils";
import GatheringQRCode from "../../components/GatheringQRCode/GatheringQRCode";

// // Call the backend function
const functions = getFunctions();

const stripePublishableKey =
  "pk_live_51PtTDNApsFqhrTRLqtI2sjc7kb6l8QNjWKNSm5OkqaTWL1SuUuSbyKMldiaZg6TqkbuAJyjCAuU4AKTRp55ShxIw00uAs0yeuG";

const stripePromise = loadStripe(stripePublishableKey);

const GatheringDashboard = () => {
  const { gatheringID } = useParams();
  const navigate = useNavigate();
  const [gatheringData, setGatheringData] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false); // Track payment process
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [attendees, setAttendees] = useState(0); // Attendees input
  const [price, setPrice] = useState(0); // Price state

  const [isGenerating, setIsGenerating] = useState(false); // Flag for generating QR code

  // Add this function within your GatheringDashboard component
  const handleTestNotification = async () => {
    const sendPushNotification = httpsCallable(
      functions,
      "sendPushNotification"
    );

    try {
      await sendPushNotification({
        gatheringId: gatheringID, // Pass the gatheringID as the payload
        title: "Test Notification",
        body: "Hello! This is a test notification.",
      });
      alert("Test notification sent!");
    } catch (error) {
      console.error("Error sending notification:", error);
      alert("Failed to send notification. Check the console for details.");
    }
  };

  // Function to handle opening the modal
  const handlePublish = () => {
    setIsModalOpen(true); // Open modal
  };

  // Function to calculate the price based on attendees
  const calculatePrice = (attendees) => {
    if (attendees <= 50) {
      return 0; // Free for less than 50 attendees
    }
    return attendees * 0.3; // 30 cents per attendee for 50 or more attendees
  };

  const handleGenerateQRCode = async () => {
    if (!gatheringData) {
      console.error("Gathering data is not available.");
      return;
    }

    setIsGenerating(true);

    try {
      // Generate the dynamic link
      const dynamicLink = `https://itiniri.app/join/${gatheringData.id}?shortcode=${gatheringData.shortcode}`;

      // Update the Firestore document with the new DynamicLink
      await ModifyGathering(gatheringData.id, { dynamicLink });

      // Update the local state with the new DynamicLink
      setGatheringData((prev) => ({
        ...prev,
        dynamicLink,
      }));

      console.log("Dynamic link generated and saved:", dynamicLink);
    } catch (error) {
      console.error("Error generating dynamic link:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  // Function to handle payment via Stripe after user confirms the number of attendees
  const handleProceedToPayment = async () => {
    const calculatedPrice = calculatePrice(attendees);
    setPrice(calculatedPrice * 100); // Convert dollars to cents for Stripe

    setIsProcessing(true); // Set loading state while processing

    try {
      // Initialize Firebase functions
      const functions = getFunctions();
      const createPaymentSession = httpsCallable(
        functions,
        "createPaymentSession"
      );

      const attendeeCount = attendees;

      // Call the Firebase function to create a payment session
      const result = await createPaymentSession({
        gatheringID,
        attendeeCount, // Send attendee count, not price
        redirectUrl: window.location.origin + "/success", // Redirect on success
        cancelUrl: window.location.origin + "/cancel", // Redirect on cancel
      });

      const sessionId = result.data.sessionId;

      // Redirect the user to Stripe Checkout
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Stripe checkout error:", error);
      }
    } catch (error) {
      console.error("Error creating payment session:", error);
    } finally {
      setIsProcessing(false); // Stop loading state after processing
    }
  };

  const toggleVisibility = async () => {
    try {
      await ModifyGathering(gatheringID, { visible: !gatheringData.visible });
      setGatheringData((prevData) => ({
        ...prevData,
        visible: !prevData.visible,
      }));
    } catch (error) {
      console.error("Error toggling visibility:", error);
    }
  };

  useEffect(() => {
    // Reset scroll behavior on component mount
    document.body.style.overflow = "auto";

    return () => {
      // Clean up overflow setting when component unmounts
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const fetchGatheringAndAnnouncements = async () => {
      if (!gatheringID) return;

      const docRef = doc(firestore, "Gatherings", gatheringID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = toCamelCase(docSnap.data());

        // Check if the user is the host or an admin
        const userDocRef = doc(firestore, "Users", auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        const isAdmin = userDocSnap.exists() && userDocSnap.data().admin;
        let teamEmails;
        let isTeamMember;
        if (data.teamEmails) {
          teamEmails = Array.isArray(data.teamEmails)
            ? data.teamEmails
            : Object.values(data.teamEmails);
          isTeamMember = teamEmails.includes(auth.currentUser.email);
        }

        if (auth.currentUser?.uid === data.hostID || isAdmin || isTeamMember) {
          setGatheringData(data);

          const fetchedAnnouncements = await FetchAnnouncements(gatheringID);
          setAnnouncements(fetchedAnnouncements.map((ann) => toCamelCase(ann)));
          setIsAuthorized(true);
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
      //console.log("dynamic link: " + gatheringData.dynamicLink)
      setLoading(false);
    };

    fetchGatheringAndAnnouncements();
  }, [gatheringID, navigate]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!isAuthorized) {
    return <div>Access Denied</div>;
  }

  return (
    <div className="dashboard-container">
      <button className="return-home-button" onClick={() => navigate("/")}>
        Go back
      </button>
      <h2 style={{ color: "#1D4250" }}>
        {gatheringData?.gatheringName} Dashboard
      </h2>
      <div className="content-area">
        <div className="left-content">
          <div className="rounded-div event-details">
            <h3>Event Details</h3>
            {/* <button onClick={handleTestNotification}>
              Send Test Notification
            </button> */}
            <p>
              <strong>Location:</strong> {gatheringData.location}
            </p>
            <p>
              <strong>Date:</strong> {formatDate(gatheringData.startDate)} to{" "}
              {formatDate(gatheringData.endDate)}
            </p>
            <p>
              <strong>Days Until Event:</strong>{" "}
              {daysUntil(gatheringData.startDate)}
            </p>
            <p>
              <strong>Description:</strong> {gatheringData.description}
            </p>
            {!gatheringData.published && (
              <button className="publish-button" onClick={handlePublish}>
                Publish Gathering
              </button>
            )}

            {gatheringData.published && (
              <div>
                <p>This gathering has been published.</p>
                <button onClick={toggleVisibility}>
                  {gatheringData?.visible
                    ? "Hide Gathering"
                    : "Make Gathering Visible"}
                </button>
              </div>
            )}

            {/* QR code display */}
            {/* <div>
              {gatheringData.dynamicLink ? (
                <GatheringQRCode dynamicLink={gatheringData.dynamicLink} />
              ) : (
                <button onClick={handleGenerateQRCode} disabled={isGenerating}>
                  {isGenerating ? "Generating QR Code..." : "Generate QR Code"}
                </button>
              )}
            </div> */}
          </div>

          <div className="rounded-div form-div">
            <h4>Customize Your Event</h4>
            <p>IMPORTANT: When making changes, make sure you click the "Save Changes" button in order to save your updates.</p>
            <GatheringCustomizationForm />
          </div>
        </div>
        <div className="mobile-message">
          <p>
            We're sorry, but the event customization form is not yet available
            on mobile devices. Please access this page from a desktop computer.
          </p>
        </div>
      </div>

      {/* Modal for selecting attendees */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Enter Expected Number of Attendees</h2>
        <input
          type="number"
          value={attendees}
          onChange={(e) => setAttendees(e.target.value)}
          placeholder="Number of attendees"
        />
        <p>
          Total Price:{" "}
          {attendees < 50 ? "Free" : `$${calculatePrice(attendees).toFixed(2)}`}
        </p>
        <button onClick={handleProceedToPayment} disabled={isProcessing}>
          {isProcessing ? "Processing..." : "Proceed to Payment"}
        </button>
      </Modal>
    </div>
  );
};

export default GatheringDashboard;
