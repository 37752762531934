import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

const DeleteGathering = async (gatheringID) => {
  try {
    console.log("Payload being sent:", { gatheringID });

    const handleDeleteGathering = httpsCallable(functions, "handleDeleteGathering");

    const result = await handleDeleteGathering({ gatheringID });

    return result.data; // Assuming the Firebase function returns { success: true }
  } catch (error) {
    console.error("Error deleting Gathering:", error);
    throw error;
  }
};

export default DeleteGathering;
