import React, { useState } from "react";
import "../../styles/components/gathering-customization-form-component.css";

const EmbedTab = ({ embedSettings, onUpdate }) => {
  const [siteUrl, setSiteUrl] = useState(embedSettings?.approvedEmbedUrl || "");
  const [embedSize, setEmbedSize] = useState(embedSettings?.embedSize || "medium");

  const handleSiteUrlChange = (e) => {
    const newUrl = e.target.value;
    setSiteUrl(newUrl);
    onUpdate({ approvedEmbedUrl: newUrl, embedSize }); // Trigger parent update
  };

  const handleEmbedSizeChange = (e) => {
    const newSize = e.target.value;
    setEmbedSize(newSize);
    onUpdate({ approvedEmbedUrl: siteUrl, embedSize: newSize }); // Trigger parent update
  };

  return (
    <div className="input-container">
      <h3 className="tab-title">Embed Settings</h3>
      <p>You can add your Schedule to your own site by generating embed code with the following settings. Simply enter the site you want to add it to, and select the desired size for the embed. You have to publish your Gathering before the embed is accessible.</p>
      <p className="input-label">Approved Site URL:</p>
      <input
        className="input-field"
        type="url"
        value={siteUrl}
        onChange={handleSiteUrlChange}
        placeholder="https://example.com"
      />

      <p className="input-label">Embed Size:</p>
      <select
        className="input-field"
        value={embedSize}
        onChange={handleEmbedSizeChange}
      >
        <option value="small">Small (400x300)</option>
        <option value="medium">Medium (800x600)</option>
        <option value="large">Large (1200x900)</option>
      </select>
    </div>
  );
};

export default EmbedTab;
