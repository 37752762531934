// src/views/AccountView/AccountView.js

import React, { useState, useEffect } from "react";
import { auth, firestore } from "../../config/FirebaseConfig";
import { sendPasswordResetEmail, deleteUser } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import "../../styles/views/account-view.css";

const AccountView = () => {
  const [userInfo, setUserInfo] = useState({ email: "", phone: "" });
  const [isEditable, setIsEditable] = useState(false); // State to track edit mode

  useEffect(() => {
    const fetchUserInfo = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(firestore, "Users", user.uid));
        if (userDoc.exists()) {
          setUserInfo(userDoc.data());
        }
      }
    };
    fetchUserInfo();
  }, []);

  const handleToggleEdit = () => {
    setIsEditable(!isEditable); // Toggle edit mode
  };

  const handlePasswordReset = () => {
    const userEmail = auth.currentUser?.email;
    if (userEmail) {
      sendPasswordResetEmail(auth, userEmail)
        .then(() => alert("Password reset email sent. Please check your inbox."))
        .catch((error) => console.error("Error sending password reset email:", error));
    } else {
      alert("No email associated with this account.");
    }
  };

  const handleDeleteAccount = async () => {
    if (isEditable) {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete your account?"
      );
      if (confirmDelete) {
        try {
          await deleteUser(auth.currentUser);
          alert("Account deleted");
        } catch (error) {
          console.error("Error deleting account:", error);
        }
      }
    }
  };

  return (
    <div className="account-view">
      <h2>Account Settings</h2>
      <button onClick={handleToggleEdit} style={{margin: '5px 0'}}>
        {isEditable ? "Lock Settings" : "Edit Settings"}
      </button>
      <form class="input-container account-settings-form">
        <label>
          Email:
          <input
            type="email"
            value={userInfo.email}
            class="input-field"
            disabled={!isEditable} // Disable field if not editable
          />
        </label>
        {/* <label>
          Phone:
          <input
            type="text"
            class="input-field"
            value={userInfo.phone || ""}
            disabled={!isEditable} // Disable field if not editable
          />
        </label> */}
        <button
          type="button"
          class="account-button"
          disabled={!isEditable} // Disable field if not editable
        >
          Save Changes
        </button>
        <button
          type="button"
          class="account-button"
          onClick={handlePasswordReset}
          disabled={!isEditable} // Disable field if not editable
        >
          Reset Password
        </button>
        <button
          type="button"
          class="account-button"
          onClick={handleDeleteAccount}
          disabled={!isEditable} // Disable field if not editable
        >
          Delete Account
        </button>
      </form>
    </div>
  );
};

export default AccountView;
