import React, { useState } from "react";
import { z } from 'zod';
import RemoveTeamMemberFromGathering from "../../api/RemoveTeamMemberFromGathering"; // Import the remove function

const emailSchema = z.string().email("Invalid email address");

const TeamTab = ({ hostEmail, teamEmails, addTeamMember, removeTeamMember, gatheringID }) => {
  const [newMemberEmail, setNewMemberEmail] = useState("");

  const handleAddTeamMember = () => {
    try {
      // Validate email input
      emailSchema.parse(newMemberEmail);
      addTeamMember(newMemberEmail);
      setNewMemberEmail(""); // Clear the input field after adding
    } catch (error) {
      alert("Error. Please check your inputs"); // Display error message if validation fails
    }
  };

  const handleRemoveTeamMember = async (email) => {
    try {
      // Validate email input
      emailSchema.parse(email);
      removeTeamMember(email);
      setNewMemberEmail(""); // Clear the input field after adding
    } catch (error) {
      alert(error.message); // Display error message if validation fails
    }
  };

  return (
    <div class="input-container">
      <h3>Team Members</h3>
      <p>Team members are given equal permissions to the Event Admin. Please only give Team Member permissions to trusted members of your team.</p>
      {/* Input for new team member email */}
      <div className="input-group">
        <input
          type="email"
          value={newMemberEmail}
          onChange={(e) => setNewMemberEmail(e.target.value)}
          placeholder="Enter team member email"
          className="team-member-input"
        />
        <button onClick={handleAddTeamMember} className="add-button">
          Add Team Member
        </button>
      </div>

      {/* Table to display team members */}
      <table className="team-members-table">
        <thead>
          <tr>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* Display host */}
          <tr key={hostEmail}>
            <td>{hostEmail}</td>
            <td>Admin</td>
            <td></td>
          </tr>
          {teamEmails.map((email, index) => (
            <tr key={index}>
              <td>{email}</td>
              <td>Team Member</td>
              <td>
                <button
                  onClick={() => handleRemoveTeamMember(email)}
                  className="remove-button"
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeamTab;
