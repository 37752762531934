import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider, CustomProvider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyBdF3EMeUS9vqPfyYaoijoIfXIei1yt5bs",
  authDomain: "owldata-52131.firebaseapp.com",
  projectId: "owldata-52131",
  storageBucket: "owldata-52131.appspot.com",
  messagingSenderId: "1023761907481",
  appId: "1:1023761907481:web:df13c4e1258e2ae3958f88",
  measurementId: "G-EMNNJ0P4EW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const auth = getAuth(app);

/**
 * Function to initialize App Check conditionally based on app use case
 */
const initializeAppCheckConditionally = async () => {
  try {
    if (typeof window !== "undefined") {
      // App Check provider logic
      if (window.location.pathname.includes("/embed")) {
        // Use a Custom Provider for embeds
        const customTokenFetcher = async () => {
          try {
            const response = await fetch("/api/get-custom-app-check-token", { method: "POST" });
            if (!response.ok) {
              throw new Error("Failed to fetch custom App Check token.");
            }
            const data = await response.json();
            return { token: data.token }; // The App Check token specific to the embed
          } catch (error) {
            console.error("Failed to fetch custom App Check token:", error);
            throw error;
          }
        };

        initializeAppCheck(app, {
          provider: new CustomProvider(customTokenFetcher),
          isTokenAutoRefreshEnabled: true, // Automatically refresh the token
        });
        console.log("App Check initialized with Custom Provider for embeds.");
      } else {
        // Use reCAPTCHA for other parts of the app/website
        const getSiteKey = httpsCallable(functions, "getAppCheckSiteKey");

        try {
          const result = await getSiteKey();
          const siteKey = result.data.siteKey; // Retrieve the site key dynamically from Firebase Function
          if (!siteKey) throw new Error("Missing reCAPTCHA site key.");

          initializeAppCheck(app, {
            provider: new ReCaptchaV3Provider(siteKey),
            isTokenAutoRefreshEnabled: true, // Automatically refresh the token
          });
          console.log("App Check initialized with reCAPTCHA.");
        } catch (error) {
          console.error("Failed to initialize reCAPTCHA App Check:", error);
        }
      }
    } else {
      console.warn("App Check initialization skipped (not running in browser).");
    }
  } catch (error) {
    console.error("Error initializing App Check:", error);
  }
};

// Initialize App Check
initializeAppCheckConditionally();

export { auth, firestore, functions, analytics };
