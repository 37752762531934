// src/components/Dropdown/Dropdown.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/components/dropdown-component.css";

const Dropdown = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  let closeTimeout;

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleMouseEnter = () => {
    clearTimeout(closeTimeout); // Cancel collapse timer if mouse re-enters
  };

  const handleMouseLeave = () => {
    closeTimeout = setTimeout(() => setIsOpen(false), 500); // Collapse after 1 second
  };

  return (
    <div
      className="dropdown"
      onClick={toggleDropdown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button className="dropdown-button">Account</button>
      {isOpen && (
        <div className="dropdown-menu">
          {options.map((option, index) => (
            <Link to={option.link}>
              <div
                key={index}
                className="dropdown-item"
                onClick={option.onClick}
                
              >
                {option.link ? option.label : option.label}
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
