import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig";
import toCamelCase from "../logic/toCamelCase";
import { Participant } from "../data/classes/Participant";

const FetchParticipant = async (
  idOrReferenceID: string,
  type: "Vendor" | "Artist",
  byReferenceID = false // Default to fetching by document ID
): Promise<Participant | null> => {
  try {
    const collectionName = type === "Vendor" ? "Vendors" : "Artists";

    if (byReferenceID) {
      const participantsRef = collection(firestore, collectionName);
      const q = query(participantsRef, where("ReferenceID", "==", idOrReferenceID));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docSnap = querySnapshot.docs[0];
        const data = toCamelCase({ id: docSnap.id, ...docSnap.data() });

        const participant = new Participant(
          data.id,
          data.name,
          data.userID,
          data.email,
          data.referenceID,
          type,
          data.website,
          data.description,
          data.gatheringIDs || [],
          data.imagePath || null // Added imagePath field
        );

        return participant;
      } else {
        console.log("No participant found for the given ReferenceID.");
        return null;
      }
    } else {
      const docRef = doc(firestore, collectionName, idOrReferenceID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = toCamelCase({ id: docSnap.id, ...docSnap.data() });

        const participant = new Participant(
          data.id,
          data.name,
          data.userID,
          data.email,
          data.referenceID,
          type,
          data.website,
          data.description,
          data.gatheringIDs || [],
          data.imagePath || null // Added imagePath field
        );

        return participant;
      } else {
        console.log("No such participant!");
        return null;
      }
    }
  } catch (error) {
    console.error("Error fetching participant: ", error);
    return null;
  }
};

export default FetchParticipant;
