import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../styles/components/embed-component.css";
import { formatTimeTo12Hour } from "../../logic/formatTimeTo12Hour";
import toCamelCase from "../../logic/toCamelCase";

export const EmbedSchedule = () => {
  const { gatheringID } = useParams(); // Extract gatheringID from URL
  const [gatheringName, setGatheringName] = useState("Schedule");
  const [embedSize, setEmbedSize] = useState("medium"); // Default size

  const [schedule, setSchedule] = useState([]);
  const [isInverted, setIsInverted] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [locationFilter, setLocationFilter] = useState("All");
  const [dayFilter, setDayFilter] = useState(null);
  const [filters, setFilters] = useState([]);
  const [collapsedDays, setCollapsedDays] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const embedDimensions = {
    small: { width: 400, height: 300 },
    medium: { width: 800, height: 600 },
    large: { width: 1200, height: 900 },
  };

  const CLOUD_FUNCTION_URL =
    "https://us-central1-owldata-52131.cloudfunctions.net/getEmbedData";
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log("Fetching data for gatheringID:", gatheringID);
        const response = await fetch(
          `${CLOUD_FUNCTION_URL}?gatheringId=${gatheringID}`
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching data: ${response.status} - ${response.statusText}`
          );
        }

        const data = await response.json();
        console.log("Fetched raw data:", data);

        const camelCaseData = toCamelCase(data);
        console.log("CamelCased data:", camelCaseData);

        setEmbedSize(camelCaseData.embedSize || "medium");


        // Convert events field if it's an object of objects
        if (
          typeof camelCaseData.events === "object" &&
          !Array.isArray(camelCaseData.events)
        ) {
          console.log("events is an object of objects. Converting to array...");
          camelCaseData.events = Object.values(camelCaseData.events).map(
            (event, index) => ({
              ...event,
              id: event.id || `Event ${index + 1}`, // Ensure each event has an id
              name: event.name || `Unnamed Event ${index + 1}`, // Default name if missing
            })
          );
        }

        console.log("Converted events array:", camelCaseData.events);

        setGatheringName(camelCaseData.gatheringName || "Schedule");
        setSchedule(camelCaseData.events || []);
        setError(null); // Clear previous errors
      } catch (err) {
        console.error("Error fetching gathering data:", err);
        setError("Failed to load schedule. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [gatheringID]);

  const toggleView = () => {
    setIsInverted((prev) => !prev);
    setLocationFilter("All");
    setDayFilter(null);

    if (!isInverted && filters.length > 0) {
      setDayFilter(filters[0]);
    }
  };

  const groupEvents = (events) => {
    const isGroupingByDay = !isInverted;
    const filtersSet = new Set();

    const groupedEvents = events.reduce((acc, event) => {
      const groupKey = isGroupingByDay
        ? event.startDate || "Unknown Date"
        : event.location || "Unknown Location";
      const displayName = isGroupingByDay
        ? event.startDate
          ? new Date(event.startDate).toLocaleDateString("en-US", {
              weekday: "long",
            })
          : "Unknown Date"
        : event.location || "Unknown Location";

      const filterKey = isGroupingByDay
        ? event.location || "Unknown Location"
        : event.startDate
        ? new Date(event.startDate).toLocaleDateString("en-US", {
            weekday: "long",
          })
        : "Unknown Date";
      filtersSet.add(filterKey);

      if (!acc[groupKey]) {
        acc[groupKey] = { displayName, events: [] };
      }
      acc[groupKey].events.push(event);
      return acc;
    }, {});

    const sortedGroupedEvents = Object.entries(groupedEvents)
      .sort(([a], [b]) => (a || "").localeCompare(b || ""))
      .map(([key, value]) => ({
        title: value.displayName,
        data: value.events.sort((a, b) =>
          (a.startTime || "").localeCompare(b.startTime || "")
        ),
      }));

    const sortedFilters = Array.from(filtersSet).sort((a, b) =>
      (a || "").localeCompare(b || "")
    );
    setFilters(isGroupingByDay ? ["All", ...sortedFilters] : sortedFilters);

    if (!isGroupingByDay && sortedFilters.length > 0 && !dayFilter) {
      setDayFilter(sortedFilters[0]);
    }

    return sortedGroupedEvents;
  };

  useEffect(() => {
    const groupedEvents = groupEvents(schedule);
    setEventsData(groupedEvents);
    const initialCollapsedDays = groupedEvents.reduce((acc, section) => {
      acc[section.title] = true; // Mark all sections as collapsed
      return acc;
    }, {});

    setCollapsedDays(initialCollapsedDays);
  }, [schedule, isInverted, locationFilter, dayFilter]);

  const toggleDayCollapse = (dayKey) => {
    setCollapsedDays((prevState) => ({
      ...prevState,
      [dayKey]: !prevState[dayKey],
    }));
  };

  if (loading) {
    return <div className="loading-message">Loading schedule...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div
      className="schedule-container"
      style={{
        width: `${embedDimensions[embedSize]?.width || 800}px`,
        height: `${embedDimensions[embedSize]?.height || 600}px`,
      }}
    >      {/* <div className="toggle-container">
        <button onClick={toggleView}>
          {isInverted ? "Sort by Day" : "Sort by Stage"}
        </button>
      </div> */}

      <h3>{gatheringName}</h3>

      <div className="filter-container">
        {filters.map((filter) => (
          <button
            key={filter}
            className={`filter-button ${
              (isInverted ? dayFilter : locationFilter) === filter
                ? "active"
                : ""
            }`}
            onClick={() =>
              isInverted ? setDayFilter(filter) : setLocationFilter(filter)
            }
          >
            {filter}
          </button>
        ))}
      </div>

      <div className="scrollable-event-list">
        <ul className="event-list">
          {eventsData.map((section) => (
            <li key={section.title} className="event-section">
              <h2
                className="section-header"
                onClick={() => toggleDayCollapse(section.title)}
              >
                {section.title}
                <span
                  className={`arrow ${
                    collapsedDays[section.title] ? "collapsed" : ""
                  }`}
                >
                  ▼
                </span>
              </h2>
              {!collapsedDays[section.title] && (
                <ul className="section-events">
                  {section.data
                    .filter((item) =>
                      isInverted
                        ? dayFilter === null ||
                          new Date(item.startDate).toLocaleDateString("en-US", {
                            weekday: "long",
                          }) === dayFilter
                        : locationFilter === "All" ||
                          item.location === locationFilter
                    )
                    .map((item, index) => (
                      <li key={index} className="event-item">
                        <div className="event-details">
                          <div style={{ fontWeight: "bold", marginBottom: 5 }}>
                            {item.eventName}
                          </div>
                          <div>{item.location}</div>
                          <div>{item.description}</div>
                        </div>
                        <div className="time-container">
                          {`${formatTimeTo12Hour(
                            item.startTime
                          )} - ${formatTimeTo12Hour(item.endTime)}`}
                        </div>
                      </li>
                    ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
