import React from 'react';

const PaymentCancel = () => {
  return (
    <div style={{margin: '20px'}}>
      <h2>Payment Canceled</h2>
      <p>You canceled the payment. Please try again if you'd like to publish the gathering.</p>
    </div>
  );
};

export default PaymentCancel;
