import React from "react";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#c5eaf7",
        color: "white",
        textAlign: "center",
        padding: "10px 0",
        marginTop: "20px",
        position: "relative",
        bottom: 0,
        width: "100%",
        borderTop: "1px solid rgb(29, 66, 80)",
        maxHeight: "30px"
      }}
    >
      <p style={{ margin: 0 }}>
        © {new Date().getFullYear()} itiniri LLC. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
