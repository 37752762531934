import React from "react";
import "../../styles/components/gathering-customization-form-component.css";
import "../../styles/components/image-uploads.css";

const MapTab = ({ mapImage, handleMapChange, mapPreviewUrl }) => {
  return (
    <div className="input-container">
      <h3 className="tab-title">Event Map</h3>
      <div className="input-container">
        <h3>Upload a new map image:</h3>
        <p>Upload your map image below. This should be a high-quality and readable image, since users will be able to zoom in to view map details.</p>
        <label className="upload-button-label" htmlFor="map-upload">
          Upload Image
        </label>
        <input
          id="map-upload"
          type="file"
          onChange={handleMapChange}
          accept="image/*"
          className="upload-button"
        />

         {/* If the user selects a new file, show the preview. */}
         {mapPreviewUrl && (
          <div>
            <p>New Image Preview:</p>
            <img className="map-image" src={mapPreviewUrl} alt="Map Preview" />
          </div>
        )}

        {/* If there's an existing image from Firestore, show it. */}
        {mapImage && (
          <div>
            <p>Existing Image:</p>
            <img className="map-image" src={mapImage} alt="Existing Map" />
          </div>
        )}

       
      </div>
    </div>
  );
};

export default MapTab;
