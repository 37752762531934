import React from "react";
import "../../styles/components/image-uploads.css";

const LogoUpload = ({ logoImage, handleLogoChange, logoPreviewUrl }) => {
  console.log("Logo Image URL:", logoImage);
  const imageToDisplay = logoPreviewUrl || logoImage;

  return (
    <div className="input-container">
      <h3>Upload a new logo image:</h3>
      <p>Upload your logo image below. This will be displayed front and center on your app interface.</p>
      <label className="upload-button-label" htmlFor="logo-upload">
        Upload Image
      </label>
      <input
        id="logo-upload"
        type="file"
        onChange={handleLogoChange}
        accept="image/*"
        className="upload-button"
      />
         {/* If the user selects a new file, show the preview. */}
         {logoPreviewUrl && (
          <div>
            <p>New Image Preview:</p>
            <img className="logo-image" src={logoPreviewUrl} alt="logo Preview" />
          </div>
        )}

        {/* If there's an existing image from Firestore, show it. */}
        {logoImage && (
          <div>
            <p>Existing Image:</p>
            <img className="logo-image" src={logoImage} alt="Existing Map" />
          </div>
        )}

      
    </div>
  );
};

export default LogoUpload;
