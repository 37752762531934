import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const JoinPage = () => {
  const { gatheringID } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const shortcode = queryParams.get("shortcode");

  useEffect(() => {
    const handleRedirect = async () => {
      const deepLink = `itiniri://join/${gatheringID}?shortcode=${shortcode}`;
      const appStoreLink = "https://apps.apple.com/us/app/itiniri/id6514323925"; // Replace with your App Store link
      const playStoreLink =
        "https://play.google.com/store/apps/details?id=com.itiniri"; // Replace with your Play Store link
      const fallbackWebLink = "https://itiniri.app/download"; // Your fallback download page

      let appOpened = false;

      const checkAppInstalled = () => {
        return new Promise((resolve) => {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.src = deepLink;
          document.body.appendChild(iframe); // Clean up iframe after check

          const timeout = setTimeout(() => {
            document.body.removeChild(iframe);
            resolve(false); // Assume not installed if timeout occurs
          }, 1500); // Detect app open via 'blur' event

          const onBlur = () => {
            clearTimeout(timeout);
            document.body.removeChild(iframe);
            window.removeEventListener("blur", onBlur);
            resolve(true); // App is installed
          };

          window.addEventListener("blur", onBlur);
        });
      };

      try {
        appOpened = await checkAppInstalled();
      } catch (error) {
        console.error("Error checking app installation:", error);
        appOpened = false;
      }

      if (appOpened) {
        // Redirect to deep link if app is installed
        window.location.href = deepLink;
      } else {
        // Redirect to App Store/Play Store or fallback web page
        const isIOS =
          /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isAndroid = /Android/.test(navigator.userAgent);

        if (isIOS) {
          window.location.href = appStoreLink;
        } else if (isAndroid) {
          window.location.href = playStoreLink;
        } else {
          window.location.href = fallbackWebLink;
        }
      }
    };

    handleRedirect();
  }, [gatheringID, shortcode]);

  return <div>Redirecting to the app...</div>;
};

export default JoinPage;
