import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../../config/FirebaseConfig'; // Your Firestore config
import { Link, useLocation } from 'react-router-dom';

const PaymentSuccess = () => {
  const [isPublished, setIsPublished] = useState(null);
  const [gatheringID, setGatheringID] = useState(null);
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation(); // This hook gives you access to the current URL location

  // Function to check the Published field from Firestore
  const checkPublishedStatus = async (gatheringID) => {
    try {
      if (!gatheringID) throw new Error('No gatheringID available');

      const docRef = doc(firestore, 'Gatherings', gatheringID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const gatheringData = docSnap.data();
        setIsPublished(gatheringData.Published);
      } else {
        throw new Error('No gathering found');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Create a new URLSearchParams object from the current location's search params
    const searchParams = new URLSearchParams(location.search);
    
    // Get the session_id and gatheringID from the query parameters
    const gatheringID = searchParams.get('gatheringID');

    // Set the sessionId and gatheringID into the state
    setGatheringID(gatheringID);

    console.log('gatheringID:', gatheringID);  // Log for debugging
  }, [location.search]); 

  useEffect(() => {
    // Only check the status if gatheringID is available
    if (gatheringID) {
      checkPublishedStatus(gatheringID);
    }
  }, [gatheringID]); // Only trigger when gatheringID changes and is defined

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{margin: '20px'}}>
      {isPublished ? (
        <div>
          <h1>Payment Successful!</h1>
          <p>Your event has been successfully published.</p>
          {/* Link back to the Gathering Dashboard */}
          <Link to={`/dashboard/${gatheringID}`}>
            <button>Back to Dashboard</button>
          </Link>
        </div>
      ) : (
        <div>
          <h1>Payment Processing Error</h1>
          <p>It looks like something went wrong with your payment or the event was not published.</p>
          <p>Please contact support if this issue persists.</p>
          {/* Still provide the link to go back to the dashboard even if there's an error */}
          <Link to={`/dashboard/${gatheringID}`}>
            <button>Back to Dashboard</button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default PaymentSuccess;
