import { collection, getDocs, QueryDocumentSnapshot, doc, getDoc } from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig";
import toCamelCase from "../logic/toCamelCase";

interface Gathering {
  id: string;
  gatheringName: string;
  startDate: string;
  endDate: string;
  location: string;
  [key: string]: any; // Allow for additional dynamic properties
}

const FetchParticipantGatherings = async (gatheringIDs: string[]): Promise<Gathering[]> => {
  console.log("Attempting to fetch participant gatherings");

  // Return early if the list of IDs is empty
  if (gatheringIDs.length === 0) {
    console.log("No GatheringIDs provided, returning empty array.");
    return [];
  }

  try {
    const gatheringPromises = gatheringIDs.map(async (id) => {
      const gatheringRef = doc(firestore, "Gatherings", id);
      const docSnap = await getDoc(gatheringRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const dataWithId = { id: docSnap.id, ...data };
        return toCamelCase(dataWithId) as Gathering;
      } else {
        console.warn(`Gathering with ID ${id} not found.`);
        return null;
      }
    });

    const gatherings = await Promise.all(gatheringPromises);
    const filteredGatherings = gatherings.filter(
      (gathering): gathering is Gathering => gathering !== null
    ); // Type guard to remove null values
    console.log(filteredGatherings);
    return filteredGatherings;
  } catch (error) {
    console.error("Error fetching gatherings: ", error);
    throw new Error("Failed to fetch gatherings"); // Propagate error
  }
};

export default FetchParticipantGatherings;
