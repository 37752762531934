import React, { useState, useEffect } from "react";
import ModifyEvent from "../../api/ModifyEvent";
import FetchParticipant from "../../api/FetchParticipant"; // Make sure you import this
import "../../styles/components/event-details.css";

export const EventDetails = ({ event, isHost, onClose, onSave }) => {
  const [participants, setParticipants] = useState([]);

  // Populate participants on mount or when event changes
  useEffect(() => {
    if (Array.isArray(event.eventParticipants)) {
      const mappedParticipants = event.eventParticipants.map((p, index) => ({
        ...p,
        rank: index + 1,
        // Provide default name if missing
        name: p.name || null,
      }));
      setParticipants(mappedParticipants);
    } else if (
      event.eventParticipants &&
      typeof event.eventParticipants === "object"
    ) {
      const participantArray = Object.values(event.eventParticipants);
      const mappedParticipants = participantArray.map((p, index) => ({
        ...p,
        rank: index + 1,
        name: p.name || null,
      }));
      setParticipants(mappedParticipants);
    } else {
      setParticipants([]);
    }
  }, [event]);

  /**
   * After we initialize participants, fetch missing names.
   * We only do this if the participant type is "Artist" (assuming eventParticipants are artists).
   * If your data can mix Vendors/Artists, you'll want to store the type somewhere too.
   */
  useEffect(() => {
    const fetchNamesForMissing = async () => {
      // If no participants or all have names, skip
      if (!participants.length) return;

      // We'll store updated participants in an array
      let updated = [...participants];
      let didUpdate = false;

      for (let i = 0; i < updated.length; i++) {
        // If there's no name, fetch from Firestore
        if (!updated[i].name && updated[i].id) {
          // Replace 'Artist' with logic if you also have Vendors here
          const participantData = await FetchParticipant(updated[i].id, "Artist", false);
          if (participantData && participantData.name) {
            updated[i] = { ...updated[i], name: participantData.name };
            didUpdate = true;
          }
        }
      }

      if (didUpdate) {
        setParticipants(updated);
      }
    };

    fetchNamesForMissing();
    // We only want this to run after participants have been set.
    // If you see an infinite loop, use extra conditions or store a local "fetched" flag.
  }, [participants]);

  const reorderParticipants = (index, direction) => {
    const updatedParticipants = [...participants];
    const swapIndex = index + direction;

    if (swapIndex >= 0 && swapIndex < updatedParticipants.length) {
      [updatedParticipants[index], updatedParticipants[swapIndex]] = [
        updatedParticipants[swapIndex],
        updatedParticipants[index],
      ];

      const reRankedParticipants = updatedParticipants.map((p, i) => ({
        ...p,
        rank: i + 1,
      }));

      setParticipants(reRankedParticipants);
    }
  };

  const saveReorderedParticipants = async () => {
    try {
      const updatedEvent = {
        ...event,
        eventParticipants: participants.map((participant, index) => ({
          ...participant,
          rank: index + 1,
        })),
      };

      await ModifyEvent(event.id, updatedEvent);

      // Notify parent
      if (onSave) {
        onSave(updatedEvent);
      }

      setParticipants(updatedEvent.eventParticipants);

      onClose(); // Close the modal after saving
    } catch (error) {
      console.error("Error saving reordered participants:", error);
      alert("Failed to save participant order. Please try again.");
    }
  };

  return (
    <div className="event-details-modal">
      <h2>Event Details</h2>
      <p>
        <strong>Name:</strong> {event.eventName}
      </p>
      <p>
        <strong>Location:</strong> {event.location}
      </p>
      <p>
        <strong>Description:</strong> {event.description}
      </p>
      {participants.length > 0 && (
        <>
      {isHost && (
        <>
          <h3>Artists</h3>
          <ul className="participant-list">
            {participants.map((participant, index) => (
              <li key={participant.id} className="participant-item">
                <span>{participant.rank}</span>
                <span>{participant.name || `Artist ${participant.id}`}</span>
                <div className="reorder-buttons">
                  <button
                    onClick={() => reorderParticipants(index, -1)}
                    disabled={index === 0}
                  >
                    ↑
                  </button>
                  <button
                    onClick={() => reorderParticipants(index, 1)}
                    disabled={index === participants.length - 1}
                  >
                    ↓
                  </button>
                  <button
                    onClick={() =>
                      setParticipants((prev) =>
                        prev.filter((p) => p.id !== participant.id)
                      )
                    }
                    className="remove-button"
                  >
                    ✖
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <button onClick={saveReorderedParticipants} className="save-button">
            Save Order
          </button>
        </>
      )}</>
      )}

      <button onClick={onClose} className="close-button">
        Close
      </button>
    </div>
  );
};

export default EventDetails;
