import React, { useState, useEffect } from "react";
import { auth, firestore } from "./config/FirebaseConfig";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

import HomeView from "./views/Home/HomeView";
import GatheringDashboard from "./views/GatheringDashboard/GatheringDashboard";
import ParticipantDashboard from "./views/ParticipantDashboard/ParticipantDashboard";
import AuthView from "./views/Auth/AuthView"; // Import the Auth component
import AdminView from "./views/AdminView/AdminView"; // Import the AdminView component
import AccountView from "./views/AccountView/AccountView";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen"; // Import the loading screen component


import Header from "./components/Header/Header";

import "./styles/globals.css";
import LandingPage from "./views/LandingPage/LandingPage";
import PaymentSuccess from "./components/PaymentComps/PaymentSuccess/PaymentSuccess";
import PaymentCancel from "./components/PaymentComps/PaymentCancel/PaymentCancel";
import JoinPage from "./views/JoinPage/JoinPage";
import EmbedView from "./views/EmbedView/EmbedView";
import Footer from "./components/Footer/Footer";

// COMMENT FOR GIT

function App() {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(firestore, "Users", currentUser.uid));
        if (userDoc.exists()) {
          setIsAdmin(userDoc.data().admin);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
      setLoading(false); // Set loading to false once auth check completes

    });
    return () => unsubscribe();
  }, []);

  const signOutUser = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <LoadingScreen />; // Display loading screen while loading
  }


  return (
    <Router>
      <div className="App" style={{minHeight: '100vh'}}>
        <Header
          title="itiniri"
          user={user}
          signOutUser={signOutUser}
          isAdmin={isAdmin}
        />

        <Routes>
          {/* <Route path="/" element={<LandingPage />} />{" "} */}
          {/* Business landing page route */}
          <Route
            path="/auth"
            element={!user ? <AuthView /> : <Navigate to="/" />}
          />
          <Route
            path="/"
            element={
              user ? <HomeView isAdmin={isAdmin} /> : <Navigate to="/auth" />
            }
          />
          <Route
            path="/dashboard/:gatheringID"
            element={<GatheringDashboard />}
          />
          <Route
            path="/vendor-dashboard/:participantID"
            element={<ParticipantDashboard type="Vendor" />}
          />
          <Route
            path="/artist-dashboard/:participantID"
            element={<ParticipantDashboard type="Artist" />}
          />          <Route
            path="/admin"
            element={isAdmin ? <AdminView /> : <Navigate to="/" />}
          />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/cancel" element={<PaymentCancel />} />
          <Route path="/account" element={user ? <AccountView /> : <Navigate to="/auth" />} />
          <Route path="/join/:gatheringID" element={<JoinPage />} />
          <Route path="/embed/:gatheringID" element={<EmbedView />} />

        </Routes>
      </div>
      <Footer /> 
    </Router>
  );
}

export default App;
