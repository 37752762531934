import React from "react";
import ColorPicker from "../ColorPicker/ColorPicker";
import LogoUpload from "../LogoUpload/LogoUpload";
import "../../styles/components/image-uploads.css";


const LogoAndColors = ({
  logoUrl,
  handleLogoChange,
  handleSetColors,
  primaryColor,
  secondaryColor,
  logoPreviewUrl
}) => {
  return (
    <div className="input-container">
      <h3 className="tab-title">Logo Image</h3>
      <LogoUpload logoImage={logoUrl} handleLogoChange={handleLogoChange} logoPreviewUrl={logoPreviewUrl} />
      {/* <ColorPicker
        initialPrimaryColor={primaryColor}
        initialSecondaryColor={secondaryColor}
        handleSetColors={handleSetColors}
      />{" "} */}
    </div>
  );
};

export default LogoAndColors;
